<template>
  <a-row :gutter="10" style="margin-top: 10px">
    <a-form :layout="formLayout" labelAlign="left" :form="searchForm" @submit="handleSubmit">
      <a-col :span="6">
        <a-form-item label="加油点" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              v-decorator="['jyd']"
              placeholder="请选择加油点"
          >
            <a-select-option :value="0">仓库</a-select-option>
            <a-select-option :value="1">加油站</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="来源" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              v-decorator="['source']"
              placeholder="请选择来源"
          >
            <a-select-option :value="0">用户</a-select-option>
            <a-select-option :value="1">后台</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item label="缴费类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              v-decorator="['jflx']"
              placeholder="请选择缴费类型"
          >
            <a-select-option :value="0">油卡加油</a-select-option>
            <a-select-option :value="1">现金加油</a-select-option>
          </a-select>
        </a-form-item>

      </a-col>
      <a-col :span="6">
        <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button style="margin-right: 10px" type="primary" html-type="submit" icon="search">搜索</a-button>
          <a-button @click="handleReset">重置表单</a-button>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item label="日期选择" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-date-picker
              style="width: 120px;margin-right: 10px"
              :value="year"
              mode="year"
              format="YYYY"
              :open="isopen"
              placeholder="选择年份"
              @change="onChange"
              @openChange="handYearChange"
              @panelChange="panelYearChange"/>
          <a-radio-group
              v-decorator="['month',{initialValue:m}]"
              :default-value="m"
              button-style="solid"
              @change="handleM"
          >
            <a-radio-button :value="item" v-for="item in 12">
              {{ item }}月
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
    </a-form>
  </a-row>
</template>
<script>
import {SelectRegion} from '@/components'
import moment from "moment";

export default {
  name: 'searchForm',
  components: {
    SelectRegion
  },

  data() {
    return {
      yearString: '',
      isopen: false,
      // 日历弹出的状态
      year: moment(),
      // moment格式

      m:new Date().getMonth() + 1,
      searchForm: this.$form.createForm(this),
      queryParam: {},
      formLayout: 'horizontal',
      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},
    }
  },
  methods: {
    handleM(m){
      this.searchForm.setFieldsValue({month:m.target.value})
      this.handleRefresh()
    },
    handleRefresh(){
      const values=this.searchForm.getFieldsValue()
      if (this.year && values.month) {
        values.time = this.year.format('YYYY')+'-'+(values.month<10?'0'+values.month:values.month)
      }
      this.$emit('handleSubmit', values)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleRefresh()
    },
    handleReset() {
      this.searchForm.resetFields()
    },

    onChange() {
      this.year = null;
      this.yearString = ''
    },
    handYearChange(open) {
      if (open) {
        this.isopen = true
      } else {
        this.isopen = false
      }
    },
    panelYearChange(value) {
      this.year = value
      this.yearString = moment(value).format('YYYY')
      this.isopen = false
      this.handleRefresh()

    }
  }
}
</script>
<style scoped lang="less">

</style>

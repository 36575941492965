<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="加油点" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['jyd', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              仓库
            </a-radio-button>
            <a-radio-button :value="1">
              加油站
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="form.getFieldValue('jyd')==1" label="缴费类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['jflx', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              油卡加油
            </a-radio-button>
            <a-radio-button :value="1">
              现金加油
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="加油日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-date-picker
              style="width:100%"
              allowClear
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              v-decorator="['jyrq',{rules: [{required: true, message: '请选择加油日期'}]}]"
          />
        </a-form-item>
        <a-form-item label="加油数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number
              :min="1"
              :max="999999999999999999"
              style="width:100%"
              placeholder="请输入加油数量"
              allowClear
              v-decorator="['jysl', {rules: [{required: true, message: '请输入加油数量'}]}]"
          />
        </a-form-item>
        <a-form-item v-if="form.getFieldValue('jyd')==1" label="加油金额" :label-col="labelCol"
                     :wrapper-col="wrapperCol">
          <a-input
              allowClear
              placeholder="请输入加油金额"
              v-decorator="['amount', {rules: [{required: true, message: '请输入加油金额'}]}]"
          />
        </a-form-item>
        <a-form-item v-if="form.getFieldValue('jyd')==1" label="加油金额类型" :label-col="labelCol"
                     :wrapper-col="wrapperCol">
          <a-radio-group
              v-decorator="['amount_type', {initialValue:0,rules: [{required: true}]}]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              自行垫付
            </a-radio-button>
            <a-radio-button :value="1">
              公司已付
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="加油单据" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <MultipleUpload
              :file="record.jydj_list ? record.jydj_list : []"
              v-decorator="['jydj',{rules: [{required: true, message: '请至少上传一张图片'}]}]"
          />
        </a-form-item>
        <a-form-item label="车辆仪表公里数" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              suffix="km"
              placeholder="请输入车辆仪表公里数"
              v-decorator="['kilometers', {rules: [{required: true, message: '请输入公里数'}]}]"
          />
        </a-form-item>

        <a-form-item label="车辆仪表公里数拍照" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <MultipleUpload
              :file="record.clybgls_list ? record.clybgls_list : []"
              v-decorator="['clybgls',{rules: [{required: true, message: '请至少上传一张图片'}]}]"
          />
        </a-form-item>

        <template v-if="changeRecord.length>0">
          <a-divider orientation="left">
            更改记录
          </a-divider>
          <table class="newtable" style="width: 100%;border-color: #e8e8e8;text-align: center" border>
            <tr style="font-weight: bold">
              <td class="file">更改人</td>
              <td class="file_name">更改内容</td>
              <td class="file_time">更改时间</td>
            </tr>
            <template v-if="changeRecord.length>0">
              <tr v-for="(item,index) in changeRecord">
                <td class="file">
                  {{ item.store_user.real_name }}
                </td>
                <td class="file_name">
                  {{ item.change_text }}
                </td>
                <td class="file_time">
                  {{ item.create_time }}
                </td>
              </tr>
            </template>
            <template v-else>
              <div style="display: flex;justify-content: center;padding: 20px">
                <a-empty
                    :image="require('@/assets/original.png')"
                    :image-style="{height: '60px'}"
                >
                </a-empty>
              </div>
            </template>
          </table>
        </template>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/vehicle/refuelLog'
import * as changeRecordApi from '@/api/vehicle/changeRecord'

import {MultipleUpload, MultipleUploadFile, UploadFile} from '@/components'
import _ from "lodash";

export default {
  components: {
    MultipleUploadFile,
    MultipleUpload,
    UploadFile
  },
  props: ['vehicleId'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      changeRecord: [],
    }
  },
  methods: {
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '增加加油记录'
      this.visible = true
      this.record = {}
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑加油记录'
      this.visible = true
      this.record = record
      this.setFieldsValue()
      this.getChangeRecord()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['jyd', 'jysl', 'jydj', 'kilometers', 'clybgls'])
        setFieldsValue(data)
        this.$nextTick(() => {
          const data = _.pick(this.record, ['jflx', 'amount', 'amount_type', 'jyrq'])
          setFieldsValue(data)
        })
      })
    },

    async getChangeRecord() {
      this.confirmLoading = true
      const {data: {list}} = await changeRecordApi.getAllList({
        record_id: this.record.vr_id,
        record_type: 0,
      })
      this.confirmLoading = false
      this.changeRecord = list
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.vehicle_id = this.vehicleId
        // 标记后台添加
        values.source = 1

        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({vrId: this.record['vr_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>

<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <!--        <a-button v-action:add type="primary" @click="handleAdd">增加接车记录</a-button>-->
        <!--        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>-->
      </div>
      <s-table
          ref="table"
          rowKey="vh_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:450}"
      >
        <div slot="status" slot-scope="text, item">
          <a-tag v-if="text==0">待确认</a-tag>
          <a-tag color="blue" v-if="text==1">已确认</a-tag>
        </div>
        <div slot="array" slot-scope="text, item">
          <div v-for="item in text">
            {{ item }}
          </div>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:confirm v-if="item.status==0" @click="handleConfirm(item)">标记已确认</a>
          <a v-action:details @click="handleDetails(item)">查看详情</a>
          <!--          <a v-action:edit @click="handleEdit(item)">编辑</a>-->
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
      <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/vehicle/vehicleHandover'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";

export default {
  name: "ShowToolList",
  components: {SearchForm, SaveForm, STable},
  data() {
    return {
      title: '交车记录',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'vh_id',
        //   align: 'center',
        //   width: 60,
        // },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          width: 70,
          scopedSlots: {customRender: 'status'}
        },
        // {
        //   title: '工具信息',
        //   dataIndex: 'tools',
        //   align: 'center',
        //   scopedSlots: {customRender: 'array'}
        // },
        // {
        //   title: '设备信息',
        //   dataIndex: 'devices',
        //   align: 'center',
        //   scopedSlots: {customRender: 'array'}
        // },
        // {
        //   title: '证件信息',
        //   dataIndex: 'cards',
        //   align: 'center',
        //   scopedSlots: {customRender: 'array'}
        // },
        {
          title: '驾驶员',
          width: 80,
          dataIndex: 'driver.personnel_name',
          align: 'center',
        },
        {
          title: '押运员',
          width: 80,
          dataIndex: 'supercargo.personnel_name',
          align: 'center',
        },

        {
          title: '地点',
          dataIndex: 'address',
          align: 'center',
        },
        // {
        //   title: '交车时间',
        //   dataIndex: 'handover_time',
        //   align: 'center',
        // },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        //
        // {
        //   title: '来源',
        //   dataIndex: 'source',
        //   align: 'center',
        //   scopedSlots: {customRender: 'source'},
        //   width: 60,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 交车记录'
      this.visible = true
      this.vehicle_id = vehicle_id
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    async handleConfirm(item) {
      const {message} = await Api.edit({vh_id: item['vh_id'], form: {status: 1}})
      this.$message.success(message, 1.5)
      this.handleRefresh()
    },

    handleDetails(item) {
      this.$refs.SaveForm.details(item)
    },

    handleDelete(item) {
      Api.deleted({vh_id: item['vh_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>

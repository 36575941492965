<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="选择工具" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              mode="multiple"
              @change="rendToolTabel"
              :filter-option="filterOption"
              v-decorator="['tool_id', {rules: [{required: true, message: '请选择工具'}]}]"
              placeholder="请选择工具"
          >
            <a-select-option v-for="item in tool_list" :value="item.tool_id" :item="item">
              {{ item.tool_name }}

              <a v-if="item.inventory>0">库存{{ item.inventory }}{{ item.tool_unit }}</a>
              <a style="color: red" v-else>库存{{ item.inventory }}{{ item.tool_unit }}</a>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="form.getFieldValue('tool_id')" label="工具明细" :labelCol="labelCol"
                     :wrapperCol="wrapperCol">
          <div class="table">
            <div class="tr">
              <div class="td">工具名称</div>
              <div class="td">剩余库存</div>
              <div class="td">申领数量</div>
            </div>
            <div class="tr" v-for="item in toolTabelList">
              <div class="td">{{ item.tool_name }}(<span style="color: #a60000">￥</span>{{ item.price }}/{{ item.tool_unit }})</div>
              <div class="td">{{ item.inventory }}{{ item.tool_unit }}</div>
              <div class="td">
                <a-input-number v-model="item.number" :min="1" :max="item.inventory" @change="getTotalAmount"/>
              </div>
            </div>
          </div>
        </a-form-item>

        <a-form-item v-if="form.getFieldValue('tool_id')" label="总金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <span style="color: #a60000">￥</span>{{ total_amount }}
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/tool'
import * as approveApi from '@/api/tool/toolApprove'
import {UploadFile} from '@/components'
import _ from "lodash";

export default {
  components: {
    UploadFile
  },
  props: ['vehicleId'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 4
      },
      // 输入框布局属性
      wrapperCol: {
        span: 18
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      // 工具列表
      tool_list: [],
      // 工具表格列表
      toolTabelList: [],
      // 总金额
      total_amount: 0
    }
  },

  mounted() {
  },
  methods: {
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    /**
     * 渲染工具表格
     * */
    rendToolTabel(values, option) {
      this.toolTabelList = option.map(({data: {attrs: {item}}}) => {
        return {
          ...item, ...{
            number: item.inventory > 0 ? 1 : 0,
          }
        }
      })

      this.getTotalAmount()
    },

    /**
     * 获取总金额
     */
    getTotalAmount() {
      this.total_amount = 0
      this.toolTabelList.map(item => this.total_amount += (item.number * item.price))
    },

    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '申领工具'
      this.visible = true
      this.getToolList()
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑工具'
      this.visible = true

      this.record = record
      this.setFieldsValue()
      this.getToolList()
    },

    /**
     * 获取工具列表
     */
    getToolList() {
      Api.selectList({tool_type: 0}).then(({data: {list}}) => {
        this.tool_list = list
      })
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['tool_id'])
        setFieldsValue(data)
        this.toolTabelList = this.record.tool_list.map(item => item)
        this.getTotalAmount()
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      let error = this.toolTabelList.find(item => item.inventory <= 0)
      if (error) {
        this.$message.error('工具明细中存在库存不足工具，请检查后再次提交')
        return
      }

      this.confirmLoading = true
      var sendApi = null

      values.toolTableList = this.toolTabelList
      values.total_amount = this.total_amount

      if (this.mode == "add") {
        values.vehicle_id = this.vehicleId
        // 标记后台添加
        values.source = 1

        sendApi = approveApi.addVehicleApprove({form: values})
      } else {
        sendApi = approveApi.editVehicleApprove({approveId: this.record['approve_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>

<template>
  <a-modal
      :title="title"
      :width="1320"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      :footer="null"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <div style="text-align: right">
          <template v-action:edit>
            <a-button v-if="mode=='edit' && isEdit==false" size="large" type="primary" style="margin-right: 10px"
                      @click="isEdit=true">编辑信息
            </a-button>
            <a-button v-if="mode=='edit' && isEdit==true" size="large" type="primary" style="margin-right: 10px"
                      @click="handleSubmit">保存车辆
            </a-button>
            <a-button v-if="mode=='edit' && isEdit==true" size="large" type="" style="margin-right: 10px"
                      @click="isEdit=false">取消
            </a-button>
          </template>
          <template v-action:scjl>
            <a-dropdown v-if="mode=='edit'">
              <a-button size="large" @click="e => e.preventDefault()">
                随车记录
                <a-icon type="down"/>
              </a-button>
              <a-menu slot="overlay">
                <a-menu-item v-action:show3d @click="handle3DShow(record)">
                  <a href="javascript:;">3D展示</a>
                </a-menu-item>
                <a-menu-item v-action:showRefuelLog @click="handleShowModel(record,'ShowRefuelLog')">
                  <a href="javascript:;">加油记录</a>
                </a-menu-item>
                <!--              <a-menu-item v-action:showUreaLog @click="handleShowModel(record,'ShowUreaLog')">-->
                <!--                <a href="javascript:;">尿素记录</a>-->
                <!--              </a-menu-item>-->
                <a-menu-item v-action:showMaintenanceLog @click="handleShowModel(record,'ShowMaintenanceLog')">
                  <a href="javascript:;">保养维修</a>
                </a-menu-item>
                <!--            <a-menu-item v-action:showWeixiuLog @click="handleShowModel(record,'ShowWeixiuLog')">-->
                <!--              <a href="javascript:;">维修记录</a>-->
                <!--            </a-menu-item>-->
                <a-menu-item v-action:showTireLog @click="handleShowModel(record,'ShowTireLog')">
                  <a href="javascript:;">轮胎记录</a>
                </a-menu-item>
                <a-menu-item v-action:showHandoverLog @click="handleShowModel(record,'ShowHandoverLog')">
                  <a href="javascript:;">交车记录</a>
                </a-menu-item>
                <a-menu-item v-action:showAccidentLog @click="handleShowModel(record,'ShowAccidentLog')">
                  <a href="javascript:;">事故记录</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>

          <a-button v-action:delete @click="handleDelete(record)" v-if="mode=='edit'" size="large" type="danger"
                    style="margin-left: 10px">删除当前车辆
          </a-button>
        </div>
        <!--        <div style="padding: 20px 10px;position: relative;border: 1px solid #e8e8e8;margin-top: 20px">-->
        <!--          <b style="font-size: 18px">选择车位卡</b>-->

        <!--          <div class="store_card">-->
        <!--            <div-->
        <!--                @click="setCard(item)"-->
        <!--                v-for="item in card_list"-->
        <!--                :class="['item' ,item.card_status==0?'not':'',card_id==item.card_id?'action':'']">-->
        <!--              <div>-->
        <!--                <div class="title2">{{ item.package_name }}</div>-->
        <!--                <div class="date">{{-->
        <!--                    item.use_number == '-1' ? '不限制车位数量' : '剩余车位数量：' + item.use_number-->
        <!--                  }}-->
        <!--                </div>-->
        <!--                <div class="date">使用期限：{{ item.start_time_text }}～{{ item.end_time_text }}</div>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <image-->
        <!--                    class="radio"-->
        <!--                    :src="item.checked?'/static/common/radio-selected.png':'/static/common/radio.png'"-->
        <!--                />-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->

        <!--        </div>-->
        <div style="padding: 20px 10px;position: relative;border: 1px solid #e8e8e8;margin-top: 20px">
          <a-row>
            <a-col :span="6">
              <a-form-item label="牵引车行驶证" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <UploadImage
                    :file="record['cl.xsz_photo']"
                    v-decorator="['cl.xsz_photo_id']"
                    @change="handleQycVehicleIdentify"
                />
                <div class="form-item-help">
                  <p class="extra">牵引车行驶证拍照上传，上传后可自动将<a>牵引车行驶证</a>信息补全</p>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="牵引车道路运输证" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <UploadImage
                    :file="record['cl.dlysz_photo']"
                    v-decorator="['cl.dlysz_photo_id']"
                    @change="handleQycRoadTransportIdentify"
                />
                <div class="form-item-help">
                  <p class="extra">车头道路运输证拍照上传，上传后可自动将<a>车头道路运输证</a>信息补全</p>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="挂车行驶证" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <UploadImage
                    :file="record['gc.xsz_photo']"
                    v-decorator="['gc.xsz_photo_id']"
                    @change="handleGcVehicleIdentify"

                />
                <div class="form-item-help">
                  <p class="extra">挂车行驶证拍照上传，上传后可自动将<a>挂车行驶证</a>信息补全</p>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="挂车道路运输证" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <UploadImage
                    :file="record['gc.dlysz_photo']"
                    v-decorator="['gc.dlysz_photo_id']"
                    @change="handleGcRoadTransportIdentify"

                />
                <div class="form-item-help">
                  <p class="extra">挂车道路运输证拍照上传，上传后可自动将<a>挂车道路运输证</a>信息补全</p>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider orientation="left">其他证件</a-divider>
          <div style="text-align: right">
            <a-button type="primary" @click="handleAdd">增加一项</a-button>
          </div>
          <table class="newtable" style="width: 100%;border-color: #e8e8e8" border>
            <tr>
              <td class="file">文件</td>
              <td class="file_name">文件名称</td>
              <td class="file_time">有效期限</td>
              <td class="file_action">操作</td>
            </tr>
            <template v-if="other_list.length>0">
              <tr v-for="(item,index) in other_list">
                <td class="file">
                  <MultipleUploadFile
                      :file="item.file_info"
                      accept=".doc,.docx,.pdf,image/jpeg,image/png,image/gif"
                      v-model="item.file_id"
                  />
                </td>
                <td class="file_name">
                  <a-input
                      placeholder="请输入文件名称"
                      v-model="item.file_name"
                  />
                </td>
                <td class="file_time">
                  <a-range-picker
                      style="width: 100%"
                      v-model="item.file_yxqx"
                  />
                </td>
                <td class="file_action">
                  <a style="color: red" @click="handleDel(index)">删除</a>
                </td>
              </tr>
            </template>
            <template v-else>
              <div style="display: flex;justify-content: center;padding: 20px">
                <a-empty
                    :image="require('@/assets/original.png')"
                    :image-style="{height: '60px'}"
                >
                  <span slot="description"> 当前数据为空，可点击<a @click="handleAdd" style="margin: 0 5px">增加一项 </a>增加 </span>
                </a-empty>
              </div>
            </template>
          </table>

          <!--          <div class="table">-->
          <!--            <div class="tr">-->
          <!--              <div class="td">文件</div>-->
          <!--              <div class="td">文件名称</div>-->
          <!--              <div class="td">有效期限</div>-->
          <!--              <div class="td">操作</div>-->
          <!--            </div>-->
          <!--            <template v-if="other_list.length>0">-->
          <!--              <div class="tr" v-for="(item,index) in other_list">-->
          <!--                <div class="td" style="height: 80px">-->
          <!--                  <MultipleUploadFile-->
          <!--                      accept="image/*"-->
          <!--                      v-model="item.file_id"-->
          <!--                  />-->
          <!--                  &lt;!&ndash;                  <UploadFile&ndash;&gt;-->
          <!--                  &lt;!&ndash;                      :forced="true"&ndash;&gt;-->
          <!--                  &lt;!&ndash;                      :file="item.file_info"&ndash;&gt;-->
          <!--                  &lt;!&ndash;                      v-model="item.file_id"&ndash;&gt;-->
          <!--                  &lt;!&ndash;                  />&ndash;&gt;-->
          <!--                </div>-->
          <!--                <div class="td">-->
          <!--                  <a-input-->
          <!--                      placeholder="请输入文件名称"-->
          <!--                      v-model="item.file_name"-->
          <!--                  />-->
          <!--                </div>-->
          <!--                <div class="td">-->
          <!--                  <a-range-picker-->
          <!--                      style="width: 100%"-->
          <!--                      v-model="item.file_yxqx"-->
          <!--                  />-->
          <!--                </div>-->
          <!--                <div class="td">-->
          <!--                  <a style="color: red" @click="handleDel(index)">删除</a>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--            <template v-else>-->
          <!--              <div class="tr" style="display: flex;justify-content: center;padding: 20px">-->
          <!--                <a-empty-->
          <!--                    :image="require('@/assets/original.png')"-->
          <!--                    :image-style="{height: '60px'}"-->
          <!--                >-->
          <!--                  <span slot="description"> 当前数据为空，可点击<a @click="handleAdd" style="margin: 0 5px">增加一项 </a>增加 </span>-->
          <!--                </a-empty>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </div>-->
          <a-divider orientation="left">基本信息</a-divider>
          <a-row>
            <a-col :span="12">
              <a-form-item label="车辆编号" :labelCol="{span: 10}" :wrapperCol="wrapperCol">
                <a-input
                    placeholder="请输入车辆编号"
                    v-decorator="['number']"
                />
              </a-form-item>
              <a-form-item label="车牌号码" :labelCol="{span: 10}" :wrapperCol="wrapperCol">
                <a-input
                    placeholder="请输入车牌号码"
                    v-decorator="['vehicle_name']"
                />
              </a-form-item>
              <a-form-item label="经营范围" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select
                    allowClear
                    v-decorator="['business_scope']"
                    placeholder="请选择经营范围"
                >
                  <a-select-option value="1类">1类</a-select-option>
                  <a-select-option value="2类">2类</a-select-option>
                  <a-select-option value="3类">3类</a-select-option>
                  <a-select-option value="4类">4类</a-select-option>
                  <a-select-option value="5类">5类</a-select-option>
                  <a-select-option value="6类">6类</a-select-option>
                  <a-select-option value="7类">7类</a-select-option>
                  <a-select-option value="8类">8类</a-select-option>
                  <a-select-option value="9类">9类</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="车辆状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-radio-group
                    v-decorator="['status', { initialValue: 0 }]"
                >
                  <a-radio :value="0">启用</a-radio>
                  <a-radio :value="1">停用</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="主班驾驶员" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select
                    allowClear
                    v-decorator="['driver_id']"
                    placeholder="请选择主班驾驶员"
                    @change="setDriver2"
                >
                  <a-select-option v-for="item in driver_list" :value="item.personnel_id">
                    {{ item.personnel_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="副班驾驶员" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select
                    allowClear
                    v-decorator="['driver_id2']"
                    placeholder="请选择副班驾驶员"
                    @change="setDriver"
                >
                  <a-select-option v-for="item in driver2_list" :value="item.personnel_id">
                    {{ item.personnel_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="绑定押运员" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select
                    allowClear
                    v-decorator="['supercargo_id']"
                    placeholder="请选择押运员"
                >
                  <a-select-option v-for="item in supercargo_list" :value="item.personnel_id">
                    {{ item.personnel_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

            </a-col>
          </a-row>
          <a-divider orientation="left">证件信息</a-divider>
          <div class="cert-info headstock">
            牵引车行驶证
            <a-row>
              <a-col :span="12">
                <a-form-item label="号牌号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入号牌号码"
                      v-decorator="['cl.xsz_hphm']"
                  />
                </a-form-item>
                <a-form-item label="车辆所有人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆所有人"
                      v-decorator="['cl.xsz_clsyr']"
                  />
                </a-form-item>
                <a-form-item label="车辆识别代号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆识别代号"
                      v-decorator="['cl.xsz_clsbdh']"
                  />
                </a-form-item>
                <a-form-item label="注册日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入注册日期"
                      v-decorator="['cl.xsz_zcrq']"
                  />
                </a-form-item>
                <a-form-item label="整备质量" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入整备质量"
                      v-decorator="['cl.xsz_zbzl']"
                  />
                </a-form-item>
                <a-form-item label="车辆类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆类型"
                      v-decorator="['cl.xsz_cllx']"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="使用性质" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入使用性质"
                      v-decorator="['cl.xsz_syxz']"
                  />
                </a-form-item>
                <a-form-item label="发动机号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入发动机号码"
                      v-decorator="['cl.xsz_fdjhm']"
                  />
                </a-form-item>
                <a-form-item label="档案编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入档案编号"
                      v-decorator="['cl.xsz_dabh']"
                  />
                </a-form-item>
                <a-form-item label="准牵引总质量" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入准牵引总质量"
                      v-decorator="['cl.xsz_zqyzzl']"
                  />
                </a-form-item>
                <a-form-item label="检验有效期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入检验有效期"
                      v-decorator="['cl.xsz_jyyxq']"
                  />
                </a-form-item>
                <a-form-item label="车辆品牌" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆品牌"
                      v-decorator="['cl.xsz_clpp']"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <div style="border-top: 1px #d9d9d9 dashed;padding: 10px 0"></div>
            牵引车道路运输证
            <a-row>
              <a-col :span="12">
                <a-form-item label="证件编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入证件编号"
                      v-decorator="['cl.dlysz_zjbh']"
                  />
                </a-form-item>
                <a-form-item label="发证日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入发证日期"
                      v-decorator="['cl.dlysz_fzrq']"
                  />
                </a-form-item>

                <a-form-item label="有效期至" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入有效期至"
                      v-decorator="['cl.dlysz_yxqz']"
                  />
                </a-form-item>
                <a-form-item label="审验有效期至" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入审验有效期至"
                      v-decorator="['cl.dlysz_syyxqz']"
                  />
                </a-form-item>

              </a-col>
              <a-col :span="12">
                <a-form-item label="业户名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入业户名称"
                      v-decorator="['cl.dlysz_yhmc']"
                  />
                </a-form-item>
                <a-form-item label="车牌号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车牌号码"
                      v-decorator="['cl.dlysz_cphm']"
                  />
                </a-form-item>
                <!--                <a-form-item label="经营范围" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
                <!--                  <a-input-->
                <!--                      placeholder="请输入经营范围"-->
                <!--                      v-decorator="['cl.dlysz_jyfw']"-->
                <!--                  />-->
                <!--                </a-form-item>-->
              </a-col>
            </a-row>
          </div>
          <div class="cert-info trailer">
            挂车行驶证
            <a-row>
              <a-col :span="12">
                <a-form-item label="号牌号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入号牌号码"
                      v-decorator="['gc.xsz_hphm']"
                  />
                </a-form-item>
                <a-form-item label="车辆所有人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆所有人"
                      v-decorator="['gc.xsz_clsyr']"
                  />
                </a-form-item>
                <a-form-item label="车辆识别代号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆识别代号"
                      v-decorator="['gc.xsz_clsbdh']"
                  />
                </a-form-item>
                <a-form-item label="注册日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入注册日期"
                      v-decorator="['gc.xsz_zcrq']"
                  />
                </a-form-item>
                <a-form-item label="整备质量" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入整备质量"
                      v-decorator="['gc.xsz_zbzl']"
                  />
                </a-form-item>
                <a-form-item label="车辆类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车辆类型"
                      v-decorator="['gc.xsz_cllx']"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="使用性质" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入使用性质"
                      v-decorator="['gc.xsz_syxz']"
                  />
                </a-form-item>
                <a-form-item label="发动机号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入发动机号码"
                      v-decorator="['gc.xsz_fdjhm']"
                  />
                </a-form-item>
                <a-form-item label="档案编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入档案编号"
                      v-decorator="['gc.xsz_dabh']"
                  />
                </a-form-item>
                <a-form-item label="核定载质量" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入核定载质量"
                      v-decorator="['gc.xsz_zqyzzl']"
                  />
                </a-form-item>
                <a-form-item label="检验有效期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入检验有效期"
                      v-decorator="['gc.xsz_jyyxq']"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <div style="border-top: 1px #d9d9d9 dashed;padding: 10px 0"></div>
            挂车道路运输证
            <a-row>
              <a-col :span="12">
                <a-form-item label="证件编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入证件编号"
                      v-decorator="['gc.dlysz_zjbh']"
                  />
                </a-form-item>
                <a-form-item label="发证日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入发证日期"
                      v-decorator="['gc.dlysz_fzrq']"
                  />
                </a-form-item>
                <a-form-item label="有效期至" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入有效期至"
                      v-decorator="['gc.dlysz_yxqz']"
                  />
                </a-form-item>
                <a-form-item label="审验有效期至" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入审验有效期至"
                      v-decorator="['gc.dlysz_syyxqz']"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="业户名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入业户名称"
                      v-decorator="['gc.dlysz_yhmc']"
                  />
                </a-form-item>
                <a-form-item label="车牌号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入车牌号码"
                      v-decorator="['gc.dlysz_cphm']"
                  />
                </a-form-item>
                <a-form-item label="经营范围" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input
                      placeholder="请输入经营范围"
                      v-decorator="['gc.dlysz_jyfw']"
                  />
                </a-form-item>

              </a-col>
            </a-row>
          </div>
          <a-divider orientation="left">保险信息</a-divider>
          <div style="text-align: right">
            <a-button type="primary" @click="handleAddInsure">增加一项</a-button>
          </div>
          <table class="newtable" style="width: 100%;border-color: #e8e8e8" border>
            <tr>
              <td class="file">保险文件</td>
              <td class="file_name">保险名称</td>
              <td class="file_time">有效期限</td>
              <td class="file_action">操作</td>
            </tr>
            <template v-if="insure_list.length>0">
              <tr v-for="(item,index) in insure_list">
                <td class="file">
                  <MultipleUploadFile
                      :file="item.file_info"
                      accept=".doc,.docx,.pdf,image/jpeg,image/png,image/gif"
                      v-model="item.file_id"
                  />
                </td>
                <td class="file_name">
                  <a-input
                      placeholder="请输入文件名称"
                      v-model="item.file_name"
                  />
                </td>
                <td class="file_time">
                  <a-range-picker
                      style="width: 100%"
                      v-model="item.file_yxqx"
                  />
                </td>
                <td class="file_action">
                  <a style="color: red" @click="handleDelInsure(index)">删除</a>
                </td>
              </tr>
            </template>
            <template v-else>
              <div style="display: flex;justify-content: center;padding: 20px">
                <a-empty
                    :image="require('@/assets/original.png')"
                    :image-style="{height: '60px'}"
                >
                  <span slot="description"> 当前数据为空，可点击<a @click="handleAddInsure"
                                                                  style="margin: 0 5px">增加一项 </a>增加 </span>
                </a-empty>
              </div>
            </template>
          </table>


          <!--          <div class="table">-->
          <!--            <div class="tr">-->
          <!--              <div class="td">保险文件</div>-->
          <!--              <div class="td">保险名称</div>-->
          <!--              <div class="td">有效期限</div>-->
          <!--              <div class="td">操作</div>-->
          <!--            </div>-->
          <!--            <template v-if="insure_list.length>0">-->
          <!--              <div class="tr" v-for="(item,index) in insure_list">-->
          <!--                <div class="td">-->
          <!--                  <UploadFile-->
          <!--                      :forced="true"-->
          <!--                      :file="item.file_info"-->
          <!--                      v-model="item.file_id"-->
          <!--                  />-->
          <!--                </div>-->
          <!--                <div class="td">-->
          <!--                  <a-input-->
          <!--                      placeholder="请输入保险名称"-->
          <!--                      v-model="item.file_name"-->
          <!--                  />-->
          <!--                </div>-->
          <!--                <div class="td">-->
          <!--                  <a-range-picker-->
          <!--                      style="width: 100%"-->
          <!--                      v-model="item.file_yxqx"-->
          <!--                  />-->
          <!--                </div>-->
          <!--                <div class="td">-->
          <!--                  <a style="color: red" @click="handleDelInsure(index)">删除</a>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--            <template v-else>-->
          <!--              <div class="tr" style="display: flex;justify-content: center;padding: 20px">-->
          <!--                <a-empty-->
          <!--                    :image="require('@/assets/original.png')"-->
          <!--                    :image-style="{height: '60px'}"-->
          <!--                >-->
          <!--                  <span slot="description"> 当前数据为空，可点击<a @click="handleAddInsure"-->
          <!--                                                                  style="margin: 0 5px">增加一项 </a>增加 </span>-->
          <!--                </a-empty>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </div>-->
          <a-row :gutter="10">
            <a-col :span="8">
              <a-divider orientation="left">交车配套工具</a-divider>
              <a-form-item>
                <a-textarea
                    v-decorator="['ptgj']"
                    placeholder="使用,隔开，示例：灭火器,头盔..."
                    :auto-size="{ minRows: 10, maxRows: 12 }"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-divider orientation="left">交车配套设备</a-divider>
              <a-form-item>
                <a-textarea
                    v-decorator="['ptsb']"
                    placeholder="使用,隔开，示例：安全设备,大灯..."
                    :auto-size="{ minRows: 10, maxRows: 12 }"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-divider orientation="left">交车车辆证件</a-divider>
              <a-form-item>
                <a-textarea
                    v-decorator="['ptzj']"
                    placeholder="使用,隔开，示例：驾驶证,行驶证..."
                    :auto-size="{ minRows: 10, maxRows: 12 }"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <div v-if="mode=='edit' && isEdit==false"
               style="position: absolute;width: 100%;height: 100%;top:0;left:0;background: rgba(255,255,255,.25)"></div>
        </div>
      </a-form>
      <div v-if="mode=='add'" style="display: flex;justify-content: end;margin-top: 20px;padding-top: 10px">
        <a-button size="large" style="margin-right: 10px" @click="handleCancel">取消</a-button>
        <a-button size="large" type="primary" @click="handleSubmit">保存车辆</a-button>
      </div>
    </a-spin>

    <ShowToolList ref="ShowToolList"/>
    <ShowRefuelLog ref="ShowRefuelLog"/>
    <ShowUreaLog ref="ShowUreaLog"/>
    <ShowMaintenanceLog ref="ShowMaintenanceLog"/>
    <ShowWeixiuLog ref="ShowWeixiuLog"/>
    <ShowTireLog ref="ShowTireLog"/>
    <ShowHandoverLog ref="ShowHandoverLog"/>
    <AddTrialForm ref="AddTrialForm"/>
    <MemberPackage ref="MemberPackage"/>
    <showMpCode ref="showMpCode"/>
    <ShowAccidentLog ref="ShowAccidentLog"/>
  </a-modal>
</template>

<script>
import * as Api from '@/api/vehicle'
import * as personnelApi from '@/api/personnel'
import * as identifyApi from '@/api/identify'
import * as cardApi from '@/api/store/card'
import {MemberPackage, MultipleUpload, MultipleUploadFile, UploadFile, UploadImage} from '@/components'
import ShowHandoverLog from "@/views/vehicle/index/handoverLog/Index.vue";
import ShowTireLog from "@/views/vehicle/index/tireLog/Index.vue";
import ShowMaintenanceLog from "@/views/vehicle/index/maintenanceLog/Index.vue";
import ShowUreaLog from "@/views/vehicle/index/ureaLog/Index.vue";
import showMpCode from "@/components/showMpCode/Index.vue";
import AddTrialForm from "@/views/vehicle/index/modules/AddTrialForm.vue";
import ShowToolList from "@/views/vehicle/index/tooApprove/Index.vue";
import ShowRefuelLog from "@/views/vehicle/index/refuelLog/Index.vue";
import ShowAccidentLog from "@/views/vehicle/index/accidentLog/Index.vue";
import ShowWeixiuLog from "@/views/vehicle/index/weixiuLog/Index.vue";
import {filterParams} from '@/utils/util'

export default {
  components: {
    MultipleUpload,
    MultipleUploadFile,
    ShowWeixiuLog,
    ShowAccidentLog,
    ShowRefuelLog,
    ShowToolList,
    AddTrialForm,
    MemberPackage,
    showMpCode,
    ShowUreaLog,
    ShowMaintenanceLog,
    ShowTireLog,
    ShowHandoverLog,
    UploadImage,
    UploadFile
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {span: 10},
      // 输入框布局属性
      wrapperCol: {span: 14},
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      // 其他证件列表
      other_list: [
        // {other_type: 0, file_id: '', file_name: '', file_yxqx: '', file_info: []}
      ],
      // 主班驾驶员选择列表
      driver_list: [],
      // 副班驾驶员选择列表
      driver2_list: [],
      // 押运员选择列表
      supercargo_list: [],

      tools: [{name: ''}],

      tires: [],

      isEdit: false,

      card_list: [],
      card_id: '',

      //保险列表
      insure_list: [
        // {other_type: 1, file_id: '', file_name: '', file_yxqx: '', file_info: []}
      ]
    }
  },
  mounted() {
    // this.getCardList()
  },
  methods: {
    setDriver(e) {
      if (!e) {
        this.getPersonnelAll()
        return
      }
      const {
        form: {
          getFieldValue,
          setFieldsValue,
        }
      } = this
      if (getFieldValue('driver_id') === e) {
        setFieldsValue({driver_id: undefined})
      }
      this.driver_list = this.driver2_list.filter(item => item.personnel_id != e)
    },
    setDriver2(e) {
      if (!e) {
        this.getPersonnelAll()
        return
      }
      const {
        form: {
          getFieldValue,
          setFieldsValue,
        }
      } = this
      if (getFieldValue('driver_id2') === e) {
        setFieldsValue({driver_id2: undefined})
      }
      this.driver2_list = this.driver_list.filter(item => item.personnel_id != e)
    },


    handle3DShow(item) {
      this.$router.push({
        path: '/vehicle/3d', query: {
          vehicle_id: item.vehicle_id,
          vehicle_name: item.vehicle_name
        }
      })
    },
    /**
     * 通用Model
     */
    handleShowModel({vehicle_id, vehicle_name}, model) {
      this.$refs[model].queryParam.vehicle_id = vehicle_id
      this.$refs[model].open(vehicle_id, vehicle_name)
    },
    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({vehicleId: item['vehicle_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },
    setCard({card_id, card_status}) {
      if (card_status == 0) {
        return false
      }
      this.card_id = card_id
    },
    async getCardList() {
      const {data: {list}} = await cardApi.selects()
      this.card_list = list
    },

    handleAddTools() {
      this.tools.push({name: ""})
    },
    handleDelTools(index) {
      this.tools.splice(index, 1)
    },

    // 牵引车证件信息识别
    handleQycVehicleIdentify(item, file) {
      const postData = {
        url: file[0].file_path
      }
      this.confirmLoading = true
      identifyApi.getVehicleLicenseInfo(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'vehicle_name': data['号牌号码'].words,
          'cl.xsz_hphm': data['号牌号码'].words,
          'cl.xsz_clsyr': data['所有人'].words,
          'cl.xsz_clsbdh': data['车辆识别代号'].words,
          'cl.xsz_zcrq': data['注册日期'].words,
          'cl.xsz_zbzl': data['整备质量'].words,
          'cl.xsz_cllx': data['车辆类型'].words,
          'cl.xsz_syxz': data['使用性质'].words,
          'cl.xsz_fdjhm': data['发动机号码'].words,
          'cl.xsz_dabh': data['档案编号'].words,
          'cl.xsz_zqyzzl': data['准牵引总质量'].words,
          'cl.xsz_jyyxq': data['检验记录'].words,
          'cl.xsz_clpp': data['品牌型号'].words,
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },

    // 牵引车道路运输证信息识别
    handleQycRoadTransportIdentify(item, file) {
      const postData = {
        url: file[0].external_url
      }
      this.confirmLoading = true
      identifyApi.getRoadTransportLicenseInfo(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'cl.dlysz_zjbh': data.zh,
          'cl.dlysz_fzrq': data.fzrq,
          'cl.dlysz_syyxqz': data.syyxqz,
          'cl.dlysz_yhmc': data.yhmc,
          'cl.dlysz_cphm': data.cphm,
          'cl.dlysz_jyfw': data.jyfw,
          'cl.dlysz_yxqz': data.yxqz
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },

    // 挂车证件信息识别
    handleGcVehicleIdentify(item, file, type) {
      const postData = {
        url: file[0].file_path
      }
      this.confirmLoading = true
      identifyApi.getVehicleLicenseInfo(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'gc.xsz_hphm': data['号牌号码'].words,
          'gc.xsz_clsyr': data['所有人'].words,
          'gc.xsz_clsbdh': data['车辆识别代号'].words,
          'gc.xsz_zcrq': data['注册日期'].words,
          'gc.xsz_zbzl': data['整备质量'].words,
          'gc.xsz_cllx': data['车辆类型'].words,
          'gc.xsz_syxz': data['使用性质'].words,
          'gc.xsz_fdjhm': data['发动机号码'].words,
          'gc.xsz_dabh': data['档案编号'].words,
          'gc.xsz_zqyzzl': data['核定载质量'].words,
          'gc.xsz_jyyxq': data['检验记录'].words,
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },

    // 挂车道路运输证信息识别
    handleGcRoadTransportIdentify(item, file) {
      const postData = {
        url: file[0].external_url
      }
      this.confirmLoading = true
      identifyApi.getRoadTransportLicenseInfo(postData).then(({data}) => {
        this.confirmLoading = false
        const formData = {
          'gc.dlysz_zjbh': data.zh,
          'gc.dlysz_fzrq': data.fzrq,
          'gc.dlysz_syyxqz': data.syyxqz,
          'gc.dlysz_yhmc': data.yhmc,
          'gc.dlysz_cphm': data.cphm,
          'gc.dlysz_jyfw': data.jyfw,
          'gc.dlysz_yxqz': data.yxqz
        }
        this.form.setFieldsValue(formData)
      }).catch(() => {
        this.confirmLoading = false
      })
    },

    // 获取人员信息
    getPersonnelAll() {
      this.confirmLoading = true
      personnelApi.unboundVehicleList({
        driver_id: this.record.driver_id,
        driver_id2: this.record.driver_id2,
        supercargo_id: this.record.supercargo_id
      }).then(({
                 data: {
                   driver_list,
                   driver2_list,
                   supercargo_list
                 }
               }) => {
        this.driver_list = driver_list
        this.driver2_list = driver2_list
        this.supercargo_list = supercargo_list
      }).finally(() => {
        this.confirmLoading = false
      })
    },

    // 增加其他证件
    handleAdd() {
      this.other_list.push({other_type: 0, file_id: '', file_name: '', file_yxqx: '', file_info: []})
    },

    handleAddInsure() {
      this.insure_list.push({other_type: 1, file_id: '', file_name: '', file_yxqx: '', file_info: []})
    },

    // 删除其他证件
    handleDel(index) {
      this.other_list.splice(index, 1)
    },

    handleDelInsure(index) {
      this.insure_list.splice(index, 1)
    },


    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '录入车辆信息'
      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '车辆信息详情'
      this.visible = true
      this.record = record
      this.getDetails()
    },

    renderKeyArray(key, data) {
      return new Promise((reslove, reject) => {
        var list = []
        for (var k in data) {
          list[key + '.' + k] = data[k]
        }
        reslove(list)
      })
    },

    async getDetails() {
      this.confirmLoading = true
      const {data: {info}} = await Api.getInfo({vehicle_id: this.record['vehicle_id']})
      const cl = await this.renderKeyArray('cl', info.cl)
      const gc = await this.renderKeyArray('gc', info.gc)
      // const clbx = await this.renderKeyArray('clbx', info.clbx)
      // const gcbx = await this.renderKeyArray('gcbx', info.gcbx)
      this.record = {
        ...this.record, ...{
          ...cl,
          ...gc,
          // ...clbx,
          // ...gcbx
        }
      }
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(filterParams(this.record), [
          ...['number', 'vehicle_name', 'business_scope', 'status', 'driver_id', 'driver_id2', 'supercargo_id', 'ptgj', 'ptsb', 'ptzj'],
          ...Object.keys(cl),
          ...Object.keys(gc),
          // ...Object.keys(clbx),
          // ...Object.keys(gcbx)
        ])
        setFieldsValue(data)
        this.$nextTick(() => {
          this.other_list = info.other_list.map(item => {
            return {
              other_type: item.other_type,
              file_id: item.file_id,
              file_name: item.file_name,
              file_yxqx: item.yxqz,
              file_info: item.file_info
            }
          })

          this.insure_list = info.insure_list.map(item => {
            return {
              other_type: item.other_type,
              file_id: item.file_id,
              file_name: item.file_name,
              file_yxqx: item.yxqz,
              file_info: item.file_info
            }
          })
          this.confirmLoading = false
          this.getPersonnelAll()
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
      this.other_list = [{file_id: '', file_name: '', file_yxqx: '', file_info: null}]
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      values.other_list = [...this.other_list, ...this.insure_list]
      values.tools = this.tools

      //
      // if (!this.card_id) {
      //   this.$message.error('请选择您要使用的车位卡！')
      //   return
      // }
      // values.card_id = this.card_id
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {

        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({vehicleId: this.record['vehicle_id'], form: values})
      }
      sendApi.then((result) => {
        this.isEdit = false
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>

<style scoped lang="less">
.card {
  width: 140px;

}

.cert-info {
  background: #f9f9f9;
  border: 1px #d9d9d9 dashed;
  padding: 10px;
}

.trailer {
  margin-top: 20px;
}

.table {
  border: 1px solid #d9d9d9;
  margin-top: 10px;

  .tr {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;

    .td {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      width: 100%;
      text-align: center;
      border-right: 1px solid #d9d9d9;

      &:last-child {
        border-right: none;
      }
    }
  }

  .tr:last-child {
    border-bottom: none
  }
}

.ant-form-item {
  margin-bottom: 0;
}

.store_card {
  cursor: pointer;
  margin-top: 10px;
  display: flex;

  .item {
    border: 2px solid #0482CB;
    border-radius: 10px;
    padding: 15px;
    //font-size: 1;
    font-weight: 400;
    color: #0482CB;
    background: #fff;
    margin-bottom: 20px;
    margin-right: 20px;

    .title2 {
      color: #0482CB;
      font-size: 22px;
      font-weight: bold;
    }

    .date {
      //font-size: 26rpx;
      text-align: right;
    }

    .radio {
      width: 26 rpx;
      height: 26 rpx;
      background: #0482CB;
      border-radius: 60 rpx;
      border: 2 rpx solid #0482CB;
    }
  }

  .action {
    color: #fff;
    background: #0482CB;

    .title2 {
      color: #fff;
    }
  }


  .not {
    opacity: .4;
  }
}


table {
  margin-top: 10px;

  td {
    padding: 8px;
  }

  .file {
    width: 25%;
  }

  .file_name {
    width: 25%;
  }

  .file_time {
    width: 25%;
  }

  .file_action {
    width: 25%;
  }
}
</style>

<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <!--      <a-tabs default-active-key="1">-->
      <!--        <a-tab-pane key="1" tab="预申报记录">-->
      <!--          &lt;!&ndash;          预申报记录&ndash;&gt;-->
      <!--          <pred :vehicleId="vehicle_id" :pd_type="1"/>-->
      <!--        </a-tab-pane>-->
      <!--        <a-tab-pane key="2" tab="已上传保养记录" force-render>-->
      <div class="table-operator">
        <!--            <a-button v-action:add type="primary" @click="handleAdd">添加保养记录</a-button>-->
        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
      </div>
      <div style="display: block">
        <s-table
            ref="table"
            rowKey="vm_id"
            :loading="isLoading"
            :columns="columns"
            :data="loadData"
            :pageSize="15"
            :scroll="{ x: 'max-content' }"
        >
          <div slot="vm_type" slot-scope="text, item">
            <a-tag v-if="text==0" color="blue">保养</a-tag>
            <a-tag v-else-if="text==1" color="red">维修</a-tag>
            <a-tag v-else>其他</a-tag>
          </div>
          <div slot="project" slot-scope="text, item">
            <div class="descriptionTxt">{{ text }}</div>
          </div>
          <div slot="amount" slot-scope="text, item">
            <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
            <a-tag color="green" v-if="item.amount_type==0">自行垫付</a-tag>
            <a-tag color="blue" v-if="item.amount_type==1">公司已付</a-tag>
          </div>
          <div slot="kilometers" slot-scope="text, item">
            <div v-if="text">{{ text }}km</div>
          </div>
          <div slot="source" slot-scope="text, item">
            {{ text == 1 ? '后台' : '小程序' }}
          </div>
          <div class="actions" slot="action" slot-scope="text, item">
            <a v-action:edit @click="handleEdit(item)">编辑</a>
            <a-popconfirm
                v-action:delete
                title="确认删除当前记录？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelete(item)"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </div>
        </s-table>

      </div>
      <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
      <!--        </a-tab-pane>-->
      <!--      </a-tabs>-->
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/vehicle/maintenanceLog'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";
import pred from "@/views/vehicle/index/pre/Index.vue";

export default {
  name: "ShowToolList",
  components: {pred, SearchForm, SaveForm, STable},
  data() {
    return {
      title: '保养维修记录',
      queryParam: {
        vm_type: ''
      },
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          title: '保养类型',
          width: 100,
          dataIndex: 'vm_type',
          scopedSlots: {customRender: 'vm_type'}
        },
        {
          title: '项目名称',
          dataIndex: 'project',
        },
        {
          title: '金额',
          dataIndex: 'amount',
          width: 200,
          scopedSlots: {customRender: 'amount'}
        },
        {
          title: '保养时间',
          dataIndex: 'vm_date',
          width: 150,

        },
        {
          title: '仪表公里数',
          dataIndex: 'kilometers',
          width: 150,
          scopedSlots: {customRender: 'kilometers'},
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          width: 150,
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        },
      ],

      isLoad: 0,
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              if (this.isLoad === 0) {
                this.$nextTick(() => {
                  this.isLoad = 1
                  this.handleRefresh()
                })
              }
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 保养维修记录'
      this.visible = true
      this.vehicle_id = vehicle_id
    },
    cancel() {
      this.isLoad = 0
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDetails() {

    },

    handleDelete(item) {
      Api.deleted({vmId: item['vm_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">
.descriptionTxt {
  width: 200px;
  word-wrap: break-word;
  padding: 2px !important;
  white-space: pre-wrap;
}
</style>

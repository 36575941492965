<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :footer="true"
      :centered="true"
      @ok="handleCancel"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div>
        <div class="item">
          <div><b>事故现场信息：</b></div>
          <a-row>
            <a-col :span="8">
              责任划分：
              <a-tag color="red" v-if="record.accident_divide==0">全责</a-tag>
              <a-tag color="orange" v-if="record.accident_divide==1">主要责任</a-tag>
              <a-tag color="pink" v-if="record.accident_divide==2">次要责任</a-tag>
              <a-tag color="green" v-if="record.accident_divide==3">无责任</a-tag>
            </a-col>
            <a-col :span="8">当事人：<a>{{ record.personnel ? record.personnel.personnel_name : '' }}</a></a-col>
            <a-col :span="8">记录时间：<a>{{ record.create_time }}</a></a-col>

            <a-col :span="8" v-if="!isEmpty(record.sgbw_file_list)">事故部位照片：
              <PreviewFile
                  :files="record.sgbw_file_list"
              />
            </a-col>
            <a-col :span="8" v-if="!isEmpty(record.xzzp_file_list)">现场照片：
              <PreviewFile
                  :files="record.xzzp_file_list"
              />
            </a-col>
            <a-col :span="8" v-if="!isEmpty(record.zrrds_file_list)">责任认定书：
              <PreviewFile
                  :files="record.zrrds_file_list"
              />
            </a-col>
            <a-col :span="8" v-if="!isEmpty(record.bxbajl_file_list)">保险报案记录：
              <PreviewFile
                  :files="record.bxbajl_file_list"
              />
            </a-col>
          </a-row>
          <div></div>
        </div>
        <div class="item" v-if="record.status==1">
          <div><b>事故后续信息：</b></div>
          <a-row>
            <a-col :span="8">事故理赔金额：<a>{{ record.claim_amount }}元</a></a-col>
            <a-col :span="8">维修金额：<a>{{ record.repair_amount }}元</a></a-col>
            <a-col :span="8">启用险种：<a>{{ record.insurance_types.join(',') }}</a></a-col>
            <a-col :span="8">维修时长：<a>{{ record.repair_days }}天</a></a-col>
            <a-col :span="8">处理人：<a>{{ record.follow_user_name }}</a></a-col>
            <a-col :span="8">处理时间：<a>{{ record.follow_time }}</a></a-col>
          </a-row>
          <div></div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>

import {PreviewFile, UploadFile, UploadImage} from '@/components'
import {isEmpty} from "@/utils/util";

export default {
  components: {
    UploadFile,
    UploadImage,
    PreviewFile
  },
  data() {
    return {
      // 标签页索引
      tabKey: 0,
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 10
      },
      // 输入框布局属性
      wrapperCol: {
        span: 14
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前记录
      record: {},

      statusArray: ['未入职', '已入职']
    }
  },
  methods: {
    isEmpty,
    /**
     * 显示对话框
     */
    open(item) {
      console.log(item)
      this.record = item
      this.title = '查看详情'
      this.visible = true
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
    },
  }
}
</script>
<style scoped lang="less">
.item {
  padding: 10px;
  border: 1px #e8e8e8 solid;
  border-radius: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}

.cert_cover {
  .image {
    height: 100px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px #e8e8e8 solid;
  }
}

</style>

import { render, staticRenderFns } from "./AddTrialForm.vue?vue&type=template&id=dae2a534&scoped=true&"
import script from "./AddTrialForm.vue?vue&type=script&lang=js&"
export * from "./AddTrialForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae2a534",
  null
  
)

export default component.exports
<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="事故理赔金额" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              suffix="元"
              allowClear
              placeholder="请输入事故理赔金额"
              v-decorator="['claim_amount']"
          />
        </a-form-item>
        <a-form-item label="维修金额" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              suffix="元"
              allowClear
              placeholder="请输入维修金额"
              v-decorator="['repair_amount']"
          />
        </a-form-item>
        <a-form-item label="启用险种" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-checkbox-group
              v-decorator="['insurance_types']"
              name="checkboxgroup"
              :options="plainOptions"
          />
          <a-checkbox style="margin-left: 10px" v-model="other_check">
            其他险种
          </a-checkbox>

          <template v-if="other_check">
            <a-button type="primary" size="small" @click="addOtherItem">增加一项</a-button>
            <div class="table">
              <div class="tr">
                <div class="td" style="width: 20%">序号</div>
                <div class="td" style="width: 50%">险种名称</div>
                <div class="td" style="width: 30%">操作</div>
              </div>
              <div class="tr" v-for="(item,index) in other_list">
                <div class="td" style="width: 20%">
                  {{ index + 1 }}
                </div>
                <div class="td" style="width: 50%">
                  <a-input
                      style="width:100%"
                      allowClear
                      placeholder="请输入险种名称"
                      v-decorator="['other_list.'+index]"
                  />
                </div>
                <div class="td" style="width: 30%">
                  <a style="color: red" @click="delOtherItem(index)">删除</a>
                </div>
              </div>
            </div>
          </template>
        </a-form-item>
        <a-form-item label="维修时长" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              suffix="天"
              allowClear
              placeholder="请输入维修时长"
              v-decorator="['repair_days']"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/vehicle/vehicleAccident'
import {isEmpty} from "@/utils/util";

export default {
  components: {},
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      plainOptions: ['商业险', '强制险', '承运险'],
      other_check: false,
      other_list: ['']
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '添加保养记录'
      this.visible = true
      this.record = {}
    },
    edit(record) {
      this.mode = "edit"
      this.title = '完善后续信息'
      this.visible = true
      this.record = record
    },
    addOtherItem() {
      this.other_list.push('')
    },
    delOtherItem(index) {
      this.other_list.splice(index, 1)
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {

      if (!isEmpty(values.other_list)) {
        values.insurance_types = [...values.insurance_types, ...values.other_list].join
      }
      console.log(values)
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({apiId: this.record['accident_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>

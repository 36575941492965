<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
      </div>
      <s-table
          ref="table"
          rowKey="accident_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{ x: 'max-content' }"
      >
        <div slot="accident_divide" slot-scope="text, item">
          <a-tag color="red" v-if="item.accident_divide==0">全责</a-tag>
          <a-tag color="orange" v-if="item.accident_divide==1">主要责任</a-tag>
          <a-tag color="pink" v-if="item.accident_divide==2">次要责任</a-tag>
          <a-tag color="green" v-if="item.accident_divide==3">无责任</a-tag>
        </div>
        <div slot="status" slot-scope="text, item">
          <a-tag color="orange" v-if="item.status==0">待完善后续</a-tag>
          <a-tag color="blue" v-if="item.status==1">已完成</a-tag>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:details @click="handleDetails(item)">查看详情</a>
          <a v-if="item.status==0" @click="handleEdit(item)">完善后续信息</a>
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
      <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
      <Details ref="DetailsModel" @handleSubmit="handleRefresh"/>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/vehicle/vehicleAccident'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm";
import Details from "./modules/DetailsModel";
import SaveForm from "./modules/SaveForm";

export default {
  name: "ShowToolList",
  components: {
    SearchForm,
    SaveForm,
    STable,
    Details,
  },
  data() {
    return {
      title: '事故记录',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          title: '责任划分',
          dataIndex: 'accident_divide',
          align: 'center',
          width: 60,
          scopedSlots: {customRender: 'accident_divide'}
        },
        {
          title: '当事人',
          dataIndex: 'personnel.personnel_name',
          align: 'center',
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          width: 60,
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '提交记录时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(item) {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDetails(item) {
      this.$refs.DetailsModel.open(item)
    },

    handleDelete(item) {
      Api.deleted({apiId: item['accident_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
